import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import type { ReactNode } from "react";

import type { AvatarData } from "./AvatarData";

const dateFormatter = new Intl.DateTimeFormat("en-GB", {
  day: "numeric",
  month: "short",
  hour: "numeric",
  minute: "numeric",
});

interface CommentData {
  id: string;
  text: string;
  createdAt: string;
}

export const Comment = ({
  comment,
  author,
  avatar,
  secondaryAction,
}: {
  comment: CommentData;
  author: string;
  avatar?: AvatarData;
  secondaryAction: ReactNode;
}) => {
  return (
    <ListItem key={comment.id} alignItems="flex-start">
      {avatar && (
        <ListItemAvatar>
          <Avatar
            src={avatar.picture}
            imgProps={{
              draggable: false,
            }}
          >
            {avatar.name?.slice(0, 2)}
          </Avatar>
        </ListItemAvatar>
      )}
      <ListItemText
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          alignItems: "flex-start",
          gap: 0.5,
        }}
        primary={comment.text}
        primaryTypographyProps={{ whiteSpace: "pre-wrap" }}
        secondary={<CommentHeader comment={comment} author={author} />}
        secondaryTypographyProps={{ variant: "subtitle2" }}
      />
      {secondaryAction && (
        <ListItemSecondaryAction sx={{ top: 10, transform: "none" }}>
          {secondaryAction}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

const Author = styled("span")`
  color: ${(props) => props.theme.palette.text.primary};
`;

export const ResolvedComment = ({
  comment,
  author,
}: {
  comment: CommentData;
  author: string;
}) => (
  <Stack>
    <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
      <CommentHeader comment={comment} author={author} />
    </Typography>
    <Typography variant="body2" sx={{ color: "text.secondary" }}>
      This thread has been resolved
    </Typography>
  </Stack>
);

const CommentHeader = ({
  comment,
  author,
}: {
  comment: CommentData;
  author: string;
}) => (
  <Stack
    direction="row"
    spacing={1}
    sx={{ viewTransitionName: `comment-${comment.id}-header` }}
  >
    <Author>{author}</Author>
    <span>•</span>
    <span>{dateFormatter.format(new Date(comment.createdAt))}</span>
  </Stack>
);
